import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { WidgetModule } from '../shared/widget/widget.module';
import { AnalyticsRoutingModule } from './analytics-routing.module';
import { AnalyticsComponent } from './analytics.component';
import { UsageAnalyticsModule } from './components/usage-analytics/usage-analytics.module';
import { VehicleAnalyticsEffects } from './pages/vehicle/store/vehicle-analytics.effects';
import { vehicleAnalyticsReducer } from './pages/vehicle/store/vehicle-analytics.reducer';
import { AnalyticsGeneralEffects } from './store/general/analytics.effects';

@NgModule({
  declarations: [AnalyticsComponent],
  imports: [
    CommonModule,
    AnalyticsRoutingModule,
    StoreModule.forFeature('analytics', [vehicleAnalyticsReducer]),
    EffectsModule.forFeature([AnalyticsGeneralEffects, VehicleAnalyticsEffects]),
    WidgetModule,
    UsageAnalyticsModule,
  ],
  exports: [],
})
export class AnalyticsModule {}
