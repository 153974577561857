<div class="zeva-page">
  <zeva-grid [columns]="2">
    <zeva-card gridItem columnSpan="one" class="tile card-fullscreen">
      <ng-container *ngFor="let faq of faqs">
        <zeva-card
          gridItem
          columnSpan="one"
          class="faq-container"
          [collapsed]="faq.collapsed"
          collapsible
          #collapsible="cardCollapsible"
        >
          <zeva-card-header class="action-header">
            <p class="mat-headline-5">{{ faq.question }}</p>
            <button mat-icon-button (click)="collapsible.toggle()">
              <mat-icon *ngIf="collapsible.collapsed && !darkMode" svgIcon="expand"></mat-icon>
              <mat-icon *ngIf="collapsible.collapsed && darkMode" svgIcon="expand-dark"></mat-icon>
              <mat-icon *ngIf="!collapsible.collapsed" svgIcon="collapse"></mat-icon>
            </button>
          </zeva-card-header>
          <div>
            <p>{{ faq.answer }}</p>
          </div>
        </zeva-card>
      </ng-container>
    </zeva-card>

    <zeva-card
      gridItem
      columnSpan="one"
      class="tile help-request"
      [ngClass]="{ 'ticket-submitted': ticketSubmitted }"
    >
      <div class="support-modal-wrapper">
        <form [formGroup]="form" *ngIf="!ticketSubmitted">
          <div class="fields-wrapper">
            <p class="mat-headline-5 title">Submit a Help Request</p>

            <p class="info">
              We're here to help! Please enter the details of your request below. Upon submission,
              you will receive an email confirmation.
            </p>

            <mat-form-field>
              <input matInput formControlName="email" type="text" readonly />
              <mat-label> Email </mat-label>
            </mat-form-field>
            <mat-form-field>
              <mat-label> Request Type </mat-label>
              <mat-select formControlName="title">
                <mat-option
                  *ngFor="let ticketType of SUPPORT_TICKET_TYPES"
                  [value]="ticketType.value"
                >
                  {{ ticketType.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label> Request Details </mat-label>
              <textarea matInput formControlName="info"></textarea>
            </mat-form-field>
          </div>
        </form>
        <div class="bottom-wrapper" *ngIf="!ticketSubmitted">
          <div *ngIf="error" class="error-text">{{ error }}</div>
          <div class="buttons-wrapper {{ error ? 'error-shown' : '' }}">
            <button
              mat-flat-button
              [disabled]="form.invalid"
              type="button"
              color="primary"
              (click)="submitTicket($event)"
            >
              Submit
            </button>
          </div>
        </div>
        <div class="submit-confirmation-dialog-container" *ngIf="ticketSubmitted">
          <p class="mat-headline-5 title">Ticket Sent!</p>
          <p class="submit-confirmation-msg">{{ submitConfirmationMsg1 }}</p>
          <p class="submit-confirmation-msg">{{ submitConfirmationMsg2 }}</p>
          <div class="new-confirm-button-wrapper">
            <button mat-stroked-button color="primary" (click)="newForm()">
              Submit another request
            </button>
          </div>
        </div>
      </div>
    </zeva-card>
  </zeva-grid>
  <core-footer class="footer_container" [ngClass]="{sidenav_open: sidenav.opened}"></core-footer>
</div>
