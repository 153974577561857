import { EChartsOption, GaugeSeriesOption } from 'echarts';

export const DEFAULT_SERIES_OPTIONS = { symbol: 'none', smooth: true };

export const UNSTACKED_PIE_SERIES_OPTIONS = {
  // TODO: Implement this on ngx-ta-echarts
  radiusAxis: { inverse: true },
  itemStyle: { borderRadius: 100 },
  polar: { radius: ['20%', '80%'] },
};

export const ECHARTS_GAUGE_AXIS_LINE_PRESET: GaugeSeriesOption['axisLine'] = {
  lineStyle: {
    width: 30,
    color: [
      [0, '#F1F2F6'],
      [1, '#F1F2F6'],
    ],
  },
};
export const ECHARTS_GAUGE_AXIS_LINE_PRESET_DARK: GaugeSeriesOption['axisLine'] = {
  lineStyle: {
    width: 30,
    color: [
      [0, '#292929'],
      [1, '#292929'],
    ],
  },
};
/** Custom echarts options for the Gauge type charts */
export const ECHARTS_GAUGE_OPTIONS_PRESET_DARK: EChartsOption = {
  series: [
    {
      type: 'gauge',
      min: 0,
      max: 200, // update this to whatever you need
      splitNumber: 18,
      radius: '100%',
      center: ['50%', '90%'],
      startAngle: 180,
      endAngle: 0,
      // ? Doesn't work with dashes
      // progress: {
      //   show: true,
      //   overlap: false,
      //   width: 30,
      // },
      axisLine: { ...ECHARTS_GAUGE_AXIS_LINE_PRESET_DARK },
      axisTick: { show: false },
      axisLabel: { show: false },
      splitLine: {
        distance: -31,
        length: 32,
        lineStyle: {
          color: '#181818',
          width: 14,
        },
      },
      pointer: {
        // eslint-disable-next-line max-len
        icon: 'path://M0.00893215 57.0923C1.20671 39.1265 2.89695 18.6097 3.70185 0.67387C3.7182 0.0854915 4.644 0.0210885 4.67606 0.669038C5.29323 13.6878 6.8273 29.878 7.74769 42.9565L8.88453 57.0515C9.08171 59.5016 7.25604 61.6482 4.80591 61.8454C2.11707 62.0861 -0.202856 59.7794 0.00893215 57.0923Z', // ? This is the svg path of /assets/speedometer-arm-simple.svg
        width: '7.5%' as any,
        offsetCenter: [0, '2%'],
        itemStyle: {
          color: '#292929',
        },
      },
      detail: {
        show: false,
        // valueAnimation: true,
        // formatter: '{value} km/h',
        // color: 'inherit',
      },
      data: [
        {
          value: 0,
        },
      ],
    },
  ],
};
/** Custom echarts options for the Gauge type charts */
export const ECHARTS_GAUGE_OPTIONS_PRESET: EChartsOption = {
  series: [
    {
      type: 'gauge',
      min: 0,
      max: 200, // update this to whatever you need
      splitNumber: 18,
      radius: '100%',
      center: ['50%', '90%'],
      startAngle: 180,
      endAngle: 0,
      // ? Doesn't work with dashes
      // progress: {
      //   show: true,
      //   overlap: false,
      //   width: 30,
      // },
      axisLine: { ...ECHARTS_GAUGE_AXIS_LINE_PRESET },
      axisTick: { show: false },
      axisLabel: { show: false },
      splitLine: {
        distance: -31,
        length: 32,
        lineStyle: {
          color: '#fff',
          width: 14,
        },
      },
      pointer: {
        // eslint-disable-next-line max-len
        icon: 'path://M0.00893215 57.0923C1.20671 39.1265 2.89695 18.6097 3.70185 0.67387C3.7182 0.0854915 4.644 0.0210885 4.67606 0.669038C5.29323 13.6878 6.8273 29.878 7.74769 42.9565L8.88453 57.0515C9.08171 59.5016 7.25604 61.6482 4.80591 61.8454C2.11707 62.0861 -0.202856 59.7794 0.00893215 57.0923Z', // ? This is the svg path of /assets/speedometer-arm-simple.svg
        width: '7.5%' as any,
        offsetCenter: [0, '2%'],
        itemStyle: {
          color: '#F1F2F6',
        },
      },
      detail: {
        show: false,
        // valueAnimation: true,
        // formatter: '{value} km/h',
        // color: 'inherit',
      },
      data: [
        {
          value: 0,
        },
      ],
    },
  ],
};
