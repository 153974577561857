// Widget Data
export const mileageDataUrl = 'analytics/mileage_data/';
export const activityDataUrl = 'analytics/activity_data/';
export const alertsDataUrl = 'analytics/alerts_data/';
export const chargingDataUrl = 'analytics/charging_data/';
export const chargingTypeDataUrl = 'analytics/charging_type_data/';
export const co2ReductionDataUrl = 'analytics/co2_reduction_data/';
export const tripDataUrl = 'analytics/trip_data/';
// Widget Support Data
export const vehicleRankingDataUrl = 'analytics/vehicle_ranking/';
// Tile Data
export const usageChargingHeatmapUrl = 'analytics/usage_charging_heatmap/';
export const usageAnalyticsUrl = 'analytics/usage_analytics/';
export const chargingAnalyticsUrl = 'analytics/charging_analytics/';
export const geofenceAlertsAnalyticsUrl = 'analytics/geofence_alerts/';
export const batteryCapacityAnalyticsUrl = 'analytics/battery_capacity/';
export const batteryEfficiencyAnalyticsUrl = 'analytics/battery_efficiency/';
export const batteryLifeAnalyticsUrl = 'analytics/battery_life/';
export const chargeCycleAnalyticsUrl = 'analytics/charge_cycles/';
export const averageDepletionAnalyticsUrl = 'analytics/depletion/';
export const batteryRatingAnalyticsUrl = 'analytics/battery_rating/';
export const roadSpeedDataUrl = 'analytics/speed_map/';
// Date Range Data
export const dataDateRangeUrl = 'vehicle/vehicle_analytics_metadata/';
