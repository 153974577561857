import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
// import { routerTransition } from '../core/core.static';
import { SidenavService } from '../core/sidenav/sidenav.service';
import { getRouterOutletState } from '../shared/helpers/utility';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss'],
  // animations: [routerTransition],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonitoringComponent implements OnInit {
  constructor(private readonly sidenav: SidenavService) {
    this.sidenav.opened = true;
  }

  ngOnInit(): void {}

  getState = getRouterOutletState;
}
