import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { LoadingModule } from '../../shared/loading/loading.module';
import { FeedbackModalComponent } from './feedback-modal.component';
import { FeedbackService } from './feedback.service';

@NgModule({
  declarations: [FeedbackModalComponent],
  imports: [
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    CommonModule,
    LoadingModule,
    StoreModule,
    RouterModule,
    MatSelectModule,
    MatIconModule,
  ],
  exports: [FeedbackModalComponent],
  providers: [FeedbackService],
})
export class FeedbackModule {}
