<mat-toolbar role="heading" class="zeva-header">
  <div class="left-header">
    <div class="sidenav-trigger" (click)="toggleSidenav()">
      <mat-icon>{{ sidenavService.opened ? 'close' : 'menu' }}</mat-icon>
    </div>
    <div class="sidenav-logo-container">
      <img src="/assets/zeva-logo-white.svg" class="sidenav-logo" />
    </div>
  </div>
  <div class="right-header">
    <div class="quick-access" *ngIf="quickAccessTemplate !== undefined">
      <ng-container *ngTemplateOutlet="quickAccessTemplate"></ng-container>
    </div>

    <!-- TODO: These may return when their functionality is implemented -->
    <!-- <mat-icon class="disabled header-search">search</mat-icon> -->
    <!-- <mat-icon class="disabled header-notifications">notifications</mat-icon> -->

    <!-- <button
      mat-flat-button
      color="primary"
      [routerLink]="'/admin/manage-subscription'"
      *ngIf="subscriptionService.isFreePlan$ | async"
      style="margin-right: 1rem"
    >
      Upgrade to Premium
    </button> -->

    <!-- Account Management Menu -->
    <div
      *ngIf="user$ | async as user"
      class="header-account"
      [ngbPopover]="accountMenu"
      [placement]="['bottom', 'bottom-right']"
    >
      <div class="full">
        <span class="name">
          {{ user.first_name }}
          <ng-container *ngIf="user.last_name"> {{ user.last_name }}</ng-container>
        </span>
        <!-- Show account name if FM & SI account is selected -->
        <div class="account" *ngIf="account && account.data_type !== AccountType.IN">
          <span class="subscript">Account:</span>
          {{ account.company_name }}
        </div>
        <!-- Show user Email if IN account or no account is selected -->
        <div class="account" *ngIf="!account || account.data_type === AccountType.IN">
          <span class="subscript">{{ user.email }}</span>
        </div>
      </div>
      <span class="short">
        <mat-icon>person_outline</mat-icon>
      </span>
    </div>
    <ng-template #accountMenu>
      <div class="account-menu">
        <ng-container *ngIf="accounts.length > 1">
          <button mat-flat-button (click)="onChangeAccount()">
            <mat-icon>transit_enterexit</mat-icon>
            <span>{{ account ? 'Change Account' : 'Select Account' }}</span>
          </button>
          <mat-divider></mat-divider>
        </ng-container>
        <button mat-flat-button routerLink="/admin/profile">
          <mat-icon>tune</mat-icon>
          <span>Preferences</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-flat-button (click)="supportClick()">
          <mat-icon>contact_support</mat-icon>
          <span>Help</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-flat-button (click)="feedbackClick()">
          <mat-icon>feedback</mat-icon>
          <span>Feedback</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-flat-button (click)="onSignOut()">
          <mat-icon>logout</mat-icon>
          <span>Sign Out</span>
        </button>
      </div>
    </ng-template>
  </div>
</mat-toolbar>
