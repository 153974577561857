import { inject } from '@angular/core';
import { CanActivateFn, Router, RoutesRecognized } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, first, map, of, switchMap, take } from 'rxjs';
import { selectSelectedCompany } from '../../../auth/store/auth.selectors';
import { CompanyDataType } from '../../../auth/utility/auth.models';
import { GlobalService } from '../../../core/services/global.service';
import { getSubscription } from '../../../shared/subscription/store/subscription.actions';
import { selectSubscription } from '../../../shared/subscription/store/subscription.selectors';
import { getVehicleList } from '../../../shared/vehicle/store/vehicle.actions';
import { selectVehicleList } from '../../../shared/vehicle/store/vehicle.selectors';

export const setupWizard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);
  const global = inject(GlobalService);

  return combineLatest([
    store.select(selectVehicleList),
    store.select(selectSelectedCompany),
    store.select(selectSubscription),
  ]).pipe(
    switchMap(([vehicles, company, subscription]) => {
      if (!vehicles && company) store.dispatch(getVehicleList());

      if (!subscription) store.dispatch(getSubscription());

      return combineLatest([of(vehicles), of(company), of(subscription)]);
    }),
    first(([vehicles, company, subscription]) => !!vehicles && !!company && !!subscription),
    switchMap(([vehicles, company, subscription]) => {
      if (!vehicles || (vehicles.length === 0 && !subscription.value?.plan_instance.plan.free)) {
        return global.referrerRoute$.pipe(
          take(1),
          map((route: RoutesRecognized | undefined) => {
            const targetRoute =
              company?.data_type === CompanyDataType.FM
                ? ['admin', 'fleet']
                : ['admin', 'subscription'];
            return router.createUrlTree(targetRoute, {
              queryParams: { no_vehicles: true },
            });
          })
        );
      }

      // if (subscription.value?.plan_instance.plan.free) {
      //   const nonArchivedVehicles = vehicles.filter((vehicle) => !vehicle.archived).length;
      //   if (nonArchivedVehicles === 0) {
      //     return global.referrerRoute$.pipe(
      //       take(1),
      //       map((route: RoutesRecognized | undefined) => {
      //         return router.createUrlTree(['admin', 'subscription'], {
      //           queryParams: { no_vehicles: true },
      //         });
      //       })
      //     );
      //   }
      // }

      const premiumVehicles = vehicles.filter(
        (vehicle) => vehicle.premium && vehicle.state === 'active'
      ).length;
      if (
        subscription &&
        subscription.value &&
        !subscription.value?.plan_instance.plan.free &&
        premiumVehicles > subscription.value.purchased_spots
      ) {
        return global.referrerRoute$.pipe(
          take(1),
          map((route: RoutesRecognized | undefined) => {
            return router.createUrlTree(['admin', 'manage-vehicles'], {
              queryParams: { continue_connect: true, force_downgrade: true, premium: false },
            });
          })
        );
      }

      return of(true);
    })
  );
};
