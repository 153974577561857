import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, tap } from 'rxjs';
import { selectUserProfile } from '../../auth/store/auth.selectors';
import { UserProfile } from '../../auth/utility/auth.models';
import { Theme, ThemeService } from '../../core/services/theme.service';
import { SidenavService } from '../../core/sidenav/sidenav.service';
import { SupportService } from '../../core/support/support.service';
import { SUPPORT_TICKET_TYPES } from '../../core/support/support.static';
import { PlanType, SubscriptionDTO } from '../../shared/subscription/subscription.models';
import { SubscriptionService } from '../../shared/subscription/subscription.service';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  error?: string;
  loading = false;
  ticketSubmitted = false;
  private _profile?: UserProfile | null;
  private _subscription?: Subscription;

  form: FormGroup = new FormGroup({
    email: new FormControl({ value: '', disabled: false }, [Validators.required.bind(this)]),
    title: new FormControl({ value: '', disabled: false }, [Validators.required.bind(this)]),
    info: new FormControl({ value: '', disabled: false }, [Validators.required.bind(this)]),
  });

  readonly submitConfirmationMsg1 = `Thanks for submitting your support ticket!
  Our team received your information and is currently reviewing your request.
  You'll hear from us soon. `;

  readonly submitConfirmationMsg2 = `A confirmation email has been sent your way.
   Thanks for your patience – we're here to help!`;

  readonly SUPPORT_TICKET_TYPES = SUPPORT_TICKET_TYPES;

  currentlyOpenIndex = -1;

  darkMode = false;

  faqs = [
    {
      question: 'How does ZEVA work?',
      answer: `ZEVA is a cloud-based telematics platform. This means that once a vehicle has been
        connected, ZEVA creates a secure connection via Tesla’s API to retrieve your vehicle’s
        unique data, which ZEVA then interprets and displays in an easy-to-understand format.
        No hardware or software installation is required, data is updated in near-real time,
        and the entire process is backed by Tesla’s strict security protocols. `,
      collapsed: true,
    },
    {
      question: 'Where is ZEVA available?',
      answer: `Currently available in Canada, the United States, and Australia, ZEVA is on
      target to become globally accessible in 2024. We’re committed to enhancing your electric
      vehicle experience, no matter where in the world you may be.`,
      collapsed: true,
    },
    {
      question: 'What if my vehicle has more than one driver?',
      answer: `If a vehicle is shared by multiple drivers, each driver can create a separate
      ZEVA account using their unique Tesla credentials.
      Alternatively, each driver can stay connected to the vehicle by logging into a single
      ZEVA account. For an optimal experience, we recommend creating a ZEVA account using the
      primary Tesla account holder’s credentials. There is no limit to the number of users or
       devices that can access a ZEVA account.`,
      collapsed: true,
    },
    {
      question: 'Why is no data available for certain elements?',
      answer: `Tesla does not offer access to historical data, which means ZEVA
      can only analyze data collected after your vehicle connects to our platform.
      Consequently, while some data and features become instantly accessible,
      others will update based upon vehicle usage and may require up to seven
      days before the data is visible.

      Should you encounter any issues accessing your data after your vehicle has
      been connected to ZEVA for more than seven days, please reach out to our support team.
      We are here to assist and will gladly investigate the matter for you.`,
      collapsed: true,
    },
    {
      question: 'What if I still have questions?',
      answer: `Haven’t found the answer you’re looking for? We’re here to help.
      Fill out the help request form on the right or email us at contact@zevaglobal.com.
      Our team is ready to answer any questions you may have.`,
      collapsed: true,
    },
  ];

  constructor(
    readonly sidenav: SidenavService,
    private readonly store: Store,
    private service: SupportService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private readonly subscriptionService: SubscriptionService,
    private readonly themeService: ThemeService
  ) {
    this.sidenav.opened = true;
  }

  ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

  ngOnInit() {
    const theme = this.themeService.currentTheme;
    if (theme === Theme.DARK) this.darkMode = true;
    else this.darkMode = false;

    const $profilePipe = this.store.select(selectUserProfile).pipe(
      tap((profile) => {
        this._profile = profile;
        if (profile?.email) this.form.controls['email'].setValue(profile?.email);
      })
    );
    this._subscription = $profilePipe.subscribe();

    const subscription$ = this.subscriptionService.subscription$.pipe(
      tap((subscription: SubscriptionDTO | null) => {
        if (subscription === null) return;
        if (subscription.plan_instance.plan.type === PlanType.COMMERCIAL) {
          this.faqs.splice(this.faqs.length - 1, 0, {
            question: 'Can I cancel my fleet’s subscription at any time?',
            answer: `Cancellations of ZEVA for Fleets subscriptions necessitate a 30-day notice.
              Should you consider canceling, please contact your dedicated ZEVA team member
              for assistance.`,
            collapsed: true,
          });
        } else {
          this.faqs.splice(this.faqs.length - 1, 0, {
            question: 'Can I cancel my subscription at anytime?',
            answer: `Yes, ZEVA for Drivers subscriptions can be canceled at any time.
              Drivers on a free subscription will have their account and information deleted
              instantly upon cancellation. Drivers on a premium subscription will continue
              to have access to their account until the end of their current payment period,
              at which time their account and information will be deleted.`,
            collapsed: true,
          });
        }
      })
    );
    this._subscription.add(subscription$.subscribe());
  }

  onSubmitTicket() {
    this.ticketSubmitted = true;
    this.cdr.markForCheck();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmitTicketError(err: any) {
    this.error = err.message;
    this.loading = false;
    this.cdr.markForCheck();
  }

  submitTicket(event: Event) {
    event.preventDefault();
    // assert that a profile ID always defined with real accounts
    if (!this._profile?.id) return;

    this.loading = true;
    this._subscription?.unsubscribe();
    this._subscription = this.service
      .supportTicket({
        id: this._profile.id,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        reply_email: this.form.value.email,
        user_email: this._profile.email,
        current_url: this.router.url,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        title: this.form.value.title,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        content: this.form.value.info,
      })
      .subscribe({
        next: this.onSubmitTicket.bind(this),
        error: this.onSubmitTicketError.bind(this),
      });
  }

  newForm() {
    this.form.controls['title'].reset();
    this.form.controls['info'].reset();
    this.ticketSubmitted = false;
    this.cdr.markForCheck();
  }
}
