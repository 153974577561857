import { Injectable } from '@angular/core';
import { RoutesRecognized } from '@angular/router';
import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { WeekStartLocale } from '../core.models';
import { IGNORED_ROUTES } from './global.service.static';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  /**
   * Navigation state container to keep up with user's journey through pages
   * and allow programmatic return to a referrer route or a reload or the current
   */
  public readonly navigationState = {
    currentRoute: new BehaviorSubject<RoutesRecognized | undefined>(undefined),
    previousRoute: new BehaviorSubject<RoutesRecognized | undefined>(undefined),
  };
  get referrerRoute$(): Observable<RoutesRecognized | undefined> {
    return this.navigationState.previousRoute.asObservable().pipe(
      map(s => {
        if (!s) return s;
        const predicate = IGNORED_ROUTES.some(x => typeof x !== 'string' && x(s.urlAfterRedirects));
        const match = IGNORED_ROUTES.includes(s.urlAfterRedirects);
        return predicate || match ? undefined : s;
      }),
      take(1)
    );
  }

  public readonly miToKm = 1.60934;
  private unit_multiplier: number = this.miToKm;
  public inKM$ = new BehaviorSubject<boolean>(true);
  getUnitMultiplier() {
    return this.unit_multiplier;
  }
  setUnitMultiplier(event: boolean) {
    this.unit_multiplier = event ? this.miToKm : 1;
    this.inKM$.next(event);
  }

  public theme$ = new BehaviorSubject<string>('light');
  private selectedTheme = 'light';
  getTheme() {
    return this.selectedTheme;
  }
  setTheme(event: string) {
    this.selectedTheme = event;
    this.theme$.next(event);
  }

  public timezone$ = new BehaviorSubject<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);
  private selectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  getTimeZone() {
    return this.selectedTimezone;
  }
  setTimeZone(event: string) {
    this.selectedTimezone = event;
    this.timezone$.next(event);
  }

  // TODO: Sync this with user config
  private _weekStart: WeekStartLocale = WeekStartLocale.SUNDAY;
  public readonly weekStartLocale$ = new BehaviorSubject<WeekStartLocale>(this._weekStart);
  getWeekStartLocale(): WeekStartLocale {
    return this._weekStart;
  }
  setWeekStartLocale(weekStart: WeekStartLocale): void {
    this._weekStart = weekStart;
    this.weekStartLocale$.next(this._weekStart);
  }

  public static readonly DAY_OF_WEEK_MAP = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  public readonly last_tos_date: Date = new Date(2024, 4, 1);
  public readonly last_pp_date: Date = new Date(2023, 8, 29);
  public readonly last_tou_date: Date = new Date(2023, 8, 29);

  constructor() {
    this.setWeekStartLocale(WeekStartLocale.SUNDAY);
  }
}
