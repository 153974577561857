import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ZEVA_DATA_PLACEHOLDER, ZEVA_NUMBER_FORMAT } from '../../../../core/core.static';

/**
 * Returns time delta value as formatted string
 * @example '19h 59m'
 */
@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
  constructor(private decimal: DecimalPipe) {}

  transform(value: number | undefined, format: 'plaintext' | 'html' = 'plaintext'): string {
    if (value === undefined) return ZEVA_DATA_PLACEHOLDER;

    const hours = this.decimal.transform(Math.floor(value / 60), ZEVA_NUMBER_FORMAT)!;
    const minutes = this.decimal.transform(Math.floor(value % 60), ZEVA_NUMBER_FORMAT)!;

    switch (format) {
      case 'html':
        return hours !== '0'
          ? `${hours}<span class="subscript">h</span> ${minutes}<span class="subscript">m</span>`
          : `${minutes}<span class="subscript">m</span>`;
      case 'plaintext':
      default:
        return hours !== '0' ? `${hours}h ${minutes}m` : `${minutes}m`;
    }
  }
}
