<div class="feedback-modal-wrapper">
  <div
    mat-dialog-title
    style="float: right; font-weight: 700; cursor: pointer"
    (click)="close()"
    class="close-btn"
  >
   <mat-icon class="close">close</mat-icon>
  </div>
  <form [formGroup]="form" *ngIf="!formSubmitted">
    <div class="feedback-logo-container">
      <img *ngIf="!darkMode" src="/assets/zeva-logo-no-icon.png" class="feedback-logo" />
      <img *ngIf="darkMode" src="/assets/zeva-logo-white.png" class="feedback-logo" />
    </div>
    <div class="fields-wrapper">
      <p class="mat-headline-3 title">Share Your Feedback</p>

      <p class="info">
        Your feedback is invaluable to us! Help shape the future of ZEVA by sharing your thoughts and suggestions below.
      </p>

      <mat-form-field>
        <input matInput formControlName="email" type="text" readonly />
        <mat-label> Email </mat-label>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Rate Your Experience</mat-label>
        <mat-select formControlName="rating">
          <mat-option value="5">5 (Excellent)</mat-option>
          <mat-option value="4">4 (Good)</mat-option>
          <mat-option value="3">3 (Average)</mat-option>
          <mat-option value="2">2 (Fair)</mat-option>
          <mat-option value="1">1 (Poor)</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label> Your Feedback </mat-label>
        <textarea matInput formControlName="info"></textarea>
      </mat-form-field>
    </div>

    <div class="bottom-wrapper">
      <div *ngIf="error" class="error-text">{{ error }}</div>
      <div class="buttons-wrapper {{ error ? 'error-shown' : '' }}">
        <button
          [ngClass]="{ 'loading-shimmer': loading }"
          mat-flat-button
          [disabled]="form.invalid"
          type="submit"
          color="primary"
          (click)="submitForm()"
        >
          Submit
        </button>
      </div>
    </div>
  </form>
  <div class="submit-confirmation-dialog-container" *ngIf="formSubmitted">
    <div class="feedback-logo-container">
      <img src="/assets/zeva-logo-no-icon.png" class="feedback-logo" />
    </div>
    <p class="mat-headline-3 title">Feedback Submitted!</p>
    <p class="submit-confirmation-msg">{{ submitConfirmationMsg1 }}</p>
    <div class="close-confirm-button-wrapper">
      <button mat-flat-button color="primary" (click)="closeDialog()">Close</button>
    </div>
  </div>
</div>
