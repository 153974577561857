import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CardGridModule } from '../../../core/card-grid/card-grid.module';
import { ContentPlaceholderModule } from '../../../shared/content-placeholder/content-placeholder.module';

import { NgxTaEChartsModule } from '@temerity-analytics/ngx-ta-echarts';
import { TimeBasedChartModule } from '../../../shared/charts/time-based-chart/time-based-chart.module';
import { DepletionAnalyticsComponent } from './depletion-analytics.component';

@NgModule({
  declarations: [DepletionAnalyticsComponent],
  exports: [DepletionAnalyticsComponent],
  imports: [
    CommonModule,
    CardGridModule,
    NgxTaEChartsModule,
    ContentPlaceholderModule,
    TimeBasedChartModule,
  ],
})
export class DepletionAnalyticsModule {}
