import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CardGridModule } from '../../../core/card-grid/card-grid.module';
import { ContentPlaceholderModule } from '../../../shared/content-placeholder/content-placeholder.module';

import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxTaEChartsModule } from '@temerity-analytics/ngx-ta-echarts';
import { TimeBasedChartModule } from '../../../shared/charts/time-based-chart/time-based-chart.module';
import { ChargeCyclesAnalyticsComponent } from './charge-cycles-analytics.component';

@NgModule({
  declarations: [ChargeCyclesAnalyticsComponent],
  exports: [ChargeCyclesAnalyticsComponent],
  imports: [
    CommonModule,
    CardGridModule,
    NgxTaEChartsModule,
    ContentPlaceholderModule,
    TimeBasedChartModule,
    MatIconModule,
    NgbPopoverModule,
    MatButtonToggleModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
})
export class ChargeCyclesAnalyticsModule {}
