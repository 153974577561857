<app-header></app-header>

<mat-sidenav-container class="zeva-main-container">

  <mat-sidenav #sidenav mode="side" [opened]="sidenavService.opened" class="sidenav">
    <app-sidenav></app-sidenav>
  </mat-sidenav>

  <mat-sidenav-content class="main-content">
    <router-outlet class="mat-app-background page-container"></router-outlet>
  </mat-sidenav-content>

</mat-sidenav-container>
