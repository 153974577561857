import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';

import { AppRoutingModule } from '../app-routing.module';

import { FeedbackModule } from './feedback/feedback.module';
import { HeaderModule } from './header/header.module';
import { HomeComponent } from './home/home.component';
import { IdleWatchComponent } from './idle-watch/idle-watch.component';
import { SidenavModule } from './sidenav/sidenav.module';
import { SupportModule } from './support/support.module';

@NgModule({
  declarations: [HomeComponent, IdleWatchComponent],
  imports: [
    CommonModule,
    HeaderModule,
    SidenavModule,
    MatSidenavModule,
    AppRoutingModule,
    SupportModule,
    FeedbackModule,
  ],
  exports: [IdleWatchComponent],
})
export class CoreModule {}
