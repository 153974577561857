import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, filter, first, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { Loadable } from 'src/app/shared/loading-state/loadable';
import {
  PENDING_SUBSCRIPTION_STATES,
  SubscriptionDTO,
} from 'src/app/shared/subscription/subscription.models';
import { SubscriptionService } from 'src/app/shared/subscription/subscription.service';
import { Vehicle } from 'src/app/shared/vehicle/models/vehicle.class';
import { getVehicleList } from 'src/app/shared/vehicle/store/vehicle.actions';
import { selectVehicleList } from 'src/app/shared/vehicle/store/vehicle.selectors';
import { getCompanies, getUserPermissions, setSelectedCompany } from '../store/auth.actions';
import { selectCompanies, selectSession } from '../store/auth.selectors';
import { Company, CompanyDataType } from './auth.models';
import { AuthService } from './auth.service';

function _hasAccountAccess(strict = true): CanActivateFn {
  return (route: ActivatedRouteSnapshot) => {
    const store = inject(Store);
    const auth = inject(AuthService);
    const router = inject(Router);
    const subscriptionService = inject(SubscriptionService);
    const url = router.getCurrentNavigation()?.finalUrl;

    const clientParam = url?.queryParamMap.get('client');

    const account$ = store.select(selectCompanies).pipe(
      filter((companies: Loadable<Company[] | null>) => !companies.isLoading),
      tap((companies: Loadable<Company[] | null>) => {
        if (companies.value === undefined && !companies.error) store.dispatch(getCompanies());
      }),
      first(
        (companies: Loadable<Company[] | null>) =>
          companies !== null && companies.value !== undefined
      ),
      mergeMap((companies: Loadable<Company[] | null>) => {
        // If there's a client parameter, check if it's valid and set it as selected account
        if (clientParam && companies.value) {
          const company = companies.value.find((c: Company) => c.company_name === clientParam);
          if (company !== undefined) {
            auth.selectedAccount = company.company_name;
            store.dispatch(setSelectedCompany({ company }));
            store.dispatch(getUserPermissions());

            // Check if the account has an active subscription
            return combineLatest([
              subscriptionService.subscription$,
              store.select(selectVehicleList),
            ]).pipe(
              tap(([, vehicles]: [SubscriptionDTO | null, Vehicle[] | undefined | null]) => {
                if (vehicles === undefined) store.dispatch(getVehicleList());
              }),
              first(
                ([, vehicles]: [SubscriptionDTO | null, Vehicle[] | undefined | null]) => !!vehicles
              ),
              map(
                ([subscription, vehicles]: [
                  SubscriptionDTO | null,
                  Vehicle[] | undefined | null
                ]) => {
                  const error = subscriptionService.subscriptionError;
                  if (subscription === null && error) {
                    if (error instanceof HttpErrorResponse && error.status !== 404)
                      return router.createUrlTree(['error', error.status]);
                    else if (typeof error === 'string') return router.createUrlTree(['error']);
                  }
                  if (route.data['skipSubscriptionGuard']) return true;

                  // CONSUMER accounts
                  if (company.data_type === CompanyDataType.IN) {
                    if (subscription === null || subscription.state in PENDING_SUBSCRIPTION_STATES)
                      return router.createUrlTree(['auth', 'select-subscription']);
                    const activeVehicles = vehicles!.filter((v: Vehicle) => v.state === 'active');
                    if (
                      !subscription.plan_instance.plan.free &&
                      subscription.purchased_spots < activeVehicles.length
                    ) {
                      return router.createUrlTree(['admin', 'manage-vehicles'], {
                        queryParams: {
                          continue_connect: true,
                          premium: false,
                          force_downgrade: true,
                        },
                      });
                    }
                    return true;
                  }

                  // COMMERCIAL accounts
                  if (subscription === null || subscription.state in PENDING_SUBSCRIPTION_STATES)
                    return router.createUrlTree(['auth', 'subscribe']);
                  return true;
                }
              )
            );
          }
        }

        // If there's no client parameter or the client parameter is invalid, redirect accordingly
        if (companies.value && (companies.value.length === 1 || auth.selectedAccount)) {
          const company =
            companies.value.length === 1
              ? companies.value[0]
              : companies.value.find((c: Company) => c.company_name === auth.selectedAccount);
          if (company !== undefined) {
            const client = company.company_name;
            if (!url) return of(router.createUrlTree(['/'], { queryParams: { client } }));
            url.queryParams['client'] = client;
            return of(url);
          }
        }
        return of(strict ? router.createUrlTree(['auth', 'select-account']) : true);
      })
    );

    return store.select(selectSession).pipe(
      first((isAuthenticated?: boolean) => !!isAuthenticated),
      switchMap(() => account$)
    );
  };
}

/**
 * Account Guard collects and synchronizes Client (ei. Account / Company) parameter from URL string,
 * checks if the current user has access to the account, and checks if the account has an active
 * subscription.
 *
 * If user has no access or an error occurred, user will be denied access to the page
 * and redirected to Account Selection screen.
 *
 * If user has access, but the account has no active subscription, user will be redirected to the
 * corresponding subscription selection screen.
 *
 * @author Anton Valeev
 */
export const hasAccountAccess = _hasAccountAccess();

/**
 * Relaxed version of hasAccountAccess guard
 * with only difference that it makes the account related checks
 * but still lets through to the route regardless of the check results
 */
export const hasAccountAccessLax = _hasAccountAccess(false);
